'use client';

import { useEffect } from 'react';

import { showToast } from 'utils/showToast';
import { type PromoCode } from 'components/StripePayments/types';
import { useSessionStorage } from 'hooks/useSessionStorage';

import { REFERRER_PROMO_SESSION_STORAGE_KEY } from '../../../constants';

interface PromoCodeValidatorProps {
  validatedPromoCode: PromoCode | null;
}

const PromoCodeEffect = ({ validatedPromoCode }: PromoCodeValidatorProps) => {
  const [, storePromoCode] = useSessionStorage<PromoCode | undefined>(
    REFERRER_PROMO_SESSION_STORAGE_KEY,
    undefined,
  );

  useEffect(() => {
    if (!validatedPromoCode) {
      showToast('error', "Referrer promo code is not valid. Discounts won't be applied", {
        style: {
          top: '6rem',
        },
      });

      return;
    }

    storePromoCode(validatedPromoCode);
  }, [storePromoCode, validatedPromoCode]);

  return null;
};

export default PromoCodeEffect;
