import { isProductionEnvironment } from 'env';

export const WEIGHTLOSS_INITIAL_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3247220&embed_form_id=1816228&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=709153&embed_form_id=897053&form_only=true&primary_color=005F6C';

export const WEIGHTLOSS_FOLLOWUP_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3247220&embed_form_id=1844654&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=649550&embed_form_id=897052&form_only=true&primary_color=005F6C';

export const WL_SYNC_INITIAL_FORM_ID = isProductionEnvironment ? '1816228' : '897053';
export const WL_FOLLOWUP_FORM_ID = isProductionEnvironment ? '1844654' : '897052';

export const WL_ENCOUNTER_FORM_ID = isProductionEnvironment ? '1994441' : '1343543';
export const LABS_CUSTOM_MODULE_ID = isProductionEnvironment ? '25960521' : '11559398';

export const PATIENT_INSTRUCTIONS_FORM_ID = isProductionEnvironment ? '1915471' : '1092215';
export const RX_SKU_CUSTOM_MODULE_ID = isProductionEnvironment ? '25630898' : '10056013';
export const RX_NAME_CUSTOM_MODULE_ID = isProductionEnvironment ? '25630901' : '10056014';

export const AUTH_COOKIE_NAME = 'auth-token';
export const CHECKOUT_COOKIE_NAME = 'checkout_session';

export const CHECKOUT_EXPIRED_MESSAGE = 'Session is expired';
export const CHECKOUT_NOT_FOUND_MESSAGE = 'Session not found';

export const CHECKOUT_EXPIRED_PARAM = 'checkout_expired';

export const AGILE_SUPPORT_EMAIL = 'support@agiletelehealth.com';

export const AGILE_SUPPORT_PHONE = '+15152182464';

export const REFERRER_PROMO_SESSION_STORAGE_KEY = 'referrer_promo';

export const PROMO_FALLBACK = isProductionEnvironment
  ? {
      id: 'promo_1Q2xq4BUVTmZaKut02rGepN7',
      couponId: 'GQ5SqjoK',
      amount_off: 7500,
      percent_off: null,
      promo_code: 'FALL75',
    }
  : {
      id: 'promo_1Q2xqrBUVTmZaKut1lCgBRio',
      couponId: 'cIxFcK4m',
      amount_off: 7500,
      percent_off: null,
      promo_code: 'FALL75STG',
    };
